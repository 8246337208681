import React ,{ useEffect, useState }from 'react';
import { Link } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl";
import api from '../../common/api';

const Services = () => {
  const intl = useIntl();

  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('services','services.json');
        setServices(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <section className="services style-3 section-padding pb-70" data-scroll-index="2">
      <div className="container">
        <div className="section-head style-3 d-flex align-items-center">
          <h3>{intl.formatMessage({ id: "our_main" })} <span>{intl.formatMessage({ id: "services" })}</span></h3> {/* <Link to="#" className="text-muted ms-5 ps-5 mt-2">{intl.formatMessage({ id: "see_full_services" })} <i className="bi bi-chevron-right ms-1"></i></Link>*/}
        </div>
        <div className="row">
          {
            services.map((service, index) => (
              <div className="col-lg-3 col-sm-6 mb-30 mb-lg-0" key={index}>
                <div className="service-card style-3">
                  <div className="icon">
                    <img src={service.icon} alt="" />
                  </div>
                  <div className="info">
                    <h5 className="title">{ intl.locale === 'en' ? service.title_en : service.title_es }</h5>
                    <div className="text">
                      {intl.locale === 'en' ?  service.details_en : service.details_es}
                    </div>
                    {/* <Link to="#"><span>{intl.formatMessage({ id: "see_projects" })}</span> <i className="bi bi-chevron-right ms-1"></i></Link> */}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default Services