import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'gatsby';
import { LanguageContext } from '../../context/LanguageContext';
import api from '../../common/api';
import translationService from '../../common/translationService'; 

const Pricing = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [plansData, setPlansData] = useState({ plans: [], text_traductions: [] });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('plan', 'plans.json');
        setPlansData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [],[currentLanguage]);

  const { plans, text_traductions } = plansData;

  if (!plansData || plansData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <section className="pricing style-3" data-scroll-index="5">
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-4">
              <div className="info">
                <div className="section-head style-3 mb-40">
                  <h3> {translationService.getTranslation(text_traductions, 'title',currentLanguage)} <span> {translationService.getTranslation(text_traductions, 'title2',currentLanguage)} </span></h3>
                </div>
                <small className="text text-gray mb-30">
                {translationService.getTranslation(text_traductions, 'subtitle1',currentLanguage)}
                </small>
                <small className="text text-gray d-block">
                {translationService.getTranslation(text_traductions, 'subtitle2',currentLanguage)} <br /> <a href="#" className="color-blue2 text-decoration-underline"> {translationService.getTranslation(text_traductions, 'contact',currentLanguage)}</a> {translationService.getTranslation(text_traductions, 'us_now',currentLanguage)}
                </small>
                <Link to="/page-services-5" className="btn rounded-pill border-blue2 hover-blue2 mt-60 sm-butn">
                  <span>{translationService.getTranslation(text_traductions, 'our_services_button',currentLanguage)}</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-7 offset-lg-1">
              <div className="price-cards">
                <div className="row">
                  {
                    plans.map((plan, i) => (
                      <div className="col-lg-6" key={i}>
                        <div className={`pricing-card ${plan.recommended ? 'dark-card' : ''} style-3 mb-30 mb-lg-0`}>
                          <div className="card-head">
                            <div className="title">
                              <h4>{plan.title[currentLanguage]} {plan.recommended && <small>{translationService.getTranslation(text_traductions, 'recommended',currentLanguage)}</small>}</h4>
                              <small>{plan.description[currentLanguage]}</small>
                            </div>
                            <div className="price">
                              <h5>{plan.price[currentLanguage]}</h5>
                              <small>{translationService.getTranslation(text_traductions, 'per_month',currentLanguage)}</small>
                            </div>
                          </div>
                          <div className="card-body">
                            <ul>
                              {
                                plan.features[currentLanguage].map((feature, i) => (
                                  <li key={i}>
                                    <i className="bi bi-check"></i>
                                    <small>{feature}</small>
                                  </li>
                                ))
                              }
                            </ul>
                          </div>
                          <Link to="#" className="btn rounded-pill bg-blue2 sm-butn w-100 text-white">
                            <span>{translationService.getTranslation(text_traductions, 'get_started_now',currentLanguage)}</span>
                          </Link>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <img src="/assets/img/testimonials/testi3_lines.png" alt="" className="testi_lines w-100" />
        </div>
      </div>
    </section>
  )
}

export default Pricing