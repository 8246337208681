import React, {useEffect, useState, useContext } from 'react';
import { Link } from 'gatsby';
import api from '../../common/api';
import { LanguageContext } from "../../context/LanguageContext";
import translationService from '../../common/translationService'; // Importa el servicio de traducción

const Projects = () => {

  const { currentLanguage } = useContext(LanguageContext);
  const [projectsData, setProjectsData] = useState({ projects: [], titles: [] });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('project', 'projects.json');
        setProjectsData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [],[currentLanguage]);
  return (
    <section className="projects section-padding style-3" data-scroll-index="4">
      <div className="container">
        <div className="section-head style-3 text-center">
          <h3>{translationService.getTranslation(projectsData.titles, 'our_featured',currentLanguage)} <span>{translationService.getTranslation(projectsData.titles, 'projects',currentLanguage)}</span></h3>
        </div>
        <div className="content">
          <div className="row">
            {
              projectsData.projects.map((project, i) => (
                <div className={i === 0 ? 'col-lg-7' : i === 1 ? 'col-lg-5' : 'col-lg-6'} key={i}>
                  <div className="project-card d-block mb-30 style-3">
                    <Link to={ project.link } className="img img-cover d-block">
                      <img src={project.image} alt="" />  
                    </Link>
                    <div className="info">
                      <h5 className="h5"><Link to={ project.link }>{ project.title }</Link></h5>
                      <small className="small"><a href="#">{ project.description }</a></small>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          {projectsData.projects.length > 4 && (
          <div className="text-center">
          <Link to="http://surteloya.com" className="btn rounded-pill bg-blue2 sm-butn mt-60 text-white">
            <span>{translationService.getTranslation(projectsData.titles, 'see_all_projects',currentLanguage)}</span>
          </Link>
        </div>  
          )}
        </div>
      </div>
    </section>
  )
}

export default Projects