import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'gatsby';
import { LanguageContext } from '../../context/LanguageContext';
import api from '../../common/api';
import translationService from '../../common/translationService';
const Footer = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [footerData, setFooterData] = useState({usefulLinks: [], services: [],titles: []});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('footerdata', 'footer.json');
        setFooterData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [], [currentLanguage]);
  return (
    <footer className="style-3">
      <div className="container">
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-3 col-sm-6">
            <div className="items">
              <div className="title">
              {translationService.getTranslation(footerData.titles, 'title',currentLanguage)}
              </div>
              <small className="text">
              {translationService.getTranslation(footerData.titles, 'subtitle1',currentLanguage)}
              </small>
              <div className="socail-icons">
                <a href="#" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="items">
              <div className="title">
              {translationService.getTranslation(footerData.titles, 'title3',currentLanguage)}
              </div>
              <small className="text mb-10 d-block">
              {translationService.getTranslation(footerData.titles, 'address',currentLanguage)}
              </small>
              <small className="text mb-10 d-block">
              {translationService.getTranslation(footerData.titles, 'phone',currentLanguage)}
              </small>
              <small className="text d-block">
                {translationService.getTranslation(footerData.titles, 'email',currentLanguage)}
              </small>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="items">
              <div className="title">
              {translationService.getTranslation(footerData.titles, 'title2',currentLanguage)}
              </div>
              <ul>
                {
                  footerData.usefulLinks.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>{item.title[currentLanguage]}</Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="items">
              <div className="title">
              {translationService.getTranslation(footerData.titles, 'title4',currentLanguage)}
              </div>
              <ul>
                {
                  footerData.services.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>{ item.title[currentLanguage] }</Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="logo">
                <img src="/assets/img/logo_b2.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <small className="small">
                © 2024 Copyrights by <a href="#" className="fw-bold text-decoration-underline">Nextream</a> All Rights Reserved. Designed by <a href="#" className="fw-bold text-decoration-underline">Nextream</a>
              </small>
            </div>
          </div>
          <img src="/assets/img/testimonials/testi3_lines.png" alt="" className="testi_lines w-100" />
        </div>
      </div>
      <img src="/assets/img/contact_globe.svg" alt="" className="contact_globe" />
    </footer>
  )
}

export default Footer