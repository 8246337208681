import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'gatsby';
import { LanguageContext } from '../../context/LanguageContext';
import api from '../../common/api';
import translationService from '../../common/translationService';

const ChatBanner = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [chatsData, setChatsData] = useState({chatsbanner:[]});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('chatsdata', 'chatbanner.json');
        setChatsData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [],[currentLanguage]);
  return (
    <section className="chat-banner style-3 section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="info">
              <h3>{translationService.getTranslation(chatsData.chatsbanner, 'title1',currentLanguage)} <span>{translationService.getTranslation(chatsData.chatsbanner, 'title2',currentLanguage)}</span></h3>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="bttns text-end">
              <Link to="#" className="btn rounded-pill bg-white border-1 border-white text-dark sm-butn me-2">
                <span>{translationService.getTranslation(chatsData.chatsbanner, 'title3',currentLanguage)}</span>
              </Link>
              <Link to="#" className="btn rounded-pill border-1 border-white text-white sm-butn">
                <span>{translationService.getTranslation(chatsData.chatsbanner, 'title4',currentLanguage)}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChatBanner