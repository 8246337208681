import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

const LanguageSwitcher = () => {
  const { languages, currentLanguage, changeLanguage } = useContext(LanguageContext);

  if (!languages || languages.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <button className="icon-35 dropdown-toggle p-0 border-0 bg-transparent rounded-circle img-cover text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src={currentLanguage === 'en' ? '/assets/img/lang.png' : '/assets/img/lang-es.png'} alt="Language flag" />
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {languages.map((lang) => (
          <button className="dropdown-item"
            key={lang}
            onClick={() => changeLanguage(lang)}
            disabled={lang === currentLanguage}>
            {lang === 'en' ? 'English' : 'Español'}
          </button>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSwitcher;