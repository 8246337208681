let instance = null;

class UrlHelper {
  constructor(locale) {
    if (!instance) {
      this.locale = locale;
      instance = this;
    }

    return instance;
  }

  setLocale(locale) {
    this.locale = locale;
  }

  getLocalizedUrl(path) {
    return `/${this.locale}${path}`;
  }
}

export default UrlHelper;