import React, { useEffect, useState, useContext } from 'react';
import api from '../../common/api';
import { LanguageContext } from '../../context/LanguageContext';
import translationService from '../../common/translationService';
const Header = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('headers', 'header.json');
        setHeaders(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [], [currentLanguage]);

  return (
    <header className="style-3 overflow-hidden" data-scroll-index="0">
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info">
                <h1 className="h1" dangerouslySetInnerHTML={{ __html:  translationService.getTranslation(headers, 'header_1',currentLanguage) }}></h1>
                <p className="p"></p>
                <h5 className="h5">{translationService.getTranslation(headers, 'header_2',currentLanguage)}<span className="fw-normal ms-1">{translationService.getTranslation(headers, 'header_3',currentLanguage)}</span></h5>
                <form action="contact.php" className="form mt-30" method="post">
                  <div className="row gx-3">
                    <div className="col-6">
                      <div className="form-group input-with-icon">
                        <input type="text" className="form-control" placeholder={translationService.getTranslation(headers, 'your_email',currentLanguage)} />
                        <span className="input-icon"><i className="far fa-envelope"></i></span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <select className="form-select" defaultValue={"Your inquiry about"}>
                          <option value="">{translationService.getTranslation(headers, 'inquiry_about',currentLanguage)}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn dark-butn hover-darkBlue rounded-pill w-100 mt-3">
                        <span>{translationService.getTranslation(headers, 'request_a_consultation',currentLanguage)}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img">
        <img src="/assets/img/header/header_3.png" alt="" className="pattern" />
        <img src="/assets/img/header/header_3_c.png" alt="" className="circle" />
        <img src="/assets/img/header/logo_sh_l.png" alt="" className="logo_shap" />
      </div>
    </header>
  )
}

export default Header