import React,{ useEffect, useState,useContext } from 'react';
import { Link } from 'gatsby';
import api from '../../common/api';
import { LanguageContext } from 'context/LanguageContext';
import UrlHelper from '../../common/urlHelper';
import translationService from '../../common/translationService'; // Importa el servicio de traducción

const About = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [dataAbout, setAboutData] = useState({numbers: [], features: [], translations: []});
  const urlHelper = new UrlHelper(currentLanguage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('about','about.json');
        setAboutData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [currentLanguage]);

  return (
    <section className="about section-padding style-3" data-scroll-index="3">
      <div className="top-content">
        <div className="img img-left">
          <img src="/assets/img/about/style_3_1.png" alt="" className="lg-circle"/>
          {
            dataAbout.numbers.map((number, index) => (
              <div className="info-circle" key={index}>
                <div className="cont">
                  <h2>{ number.value }</h2>
                  <small>{ number.title && number.title[currentLanguage] }</small>
                </div>
              </div>
            ))
          }
        </div>
        <div className="container">
          <div className="row gx-0 justify-content-end">
            <div className="col-lg-5">
              <div className="info">
                <div className="section-head long-shape mb-40 style-3" dangerouslySetInnerHTML={{ __html: translationService.getTranslation(dataAbout.translations, 'about_title1',currentLanguage) }}>
                  
                </div>
                <h5 className="h5">
                  {translationService.getTranslation(dataAbout.translations, 'about_title2',currentLanguage)}
                </h5>
                <div className="text mb-20">
                  {translationService.getTranslation(dataAbout.translations, 'about_title3',currentLanguage)}
                </div>
                <div className="text mb-70">
                  {translationService.getTranslation(dataAbout.translations, 'about_title4',currentLanguage)}
                </div>
                <Link to={urlHelper.getLocalizedUrl('#')} className="btn rounded-pill bg-blue2 sm-butn text-white">
                  <span>{translationService.getTranslation(dataAbout.translations, 'more_about',currentLanguage)}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btm-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="info pe-lg-5">
                <div className="section-head mb-40 style-3" dangerouslySetInnerHTML={{ __html: translationService.getTranslation(dataAbout.translations, 'about_title5',currentLanguage) }}>
                 
                </div>
                <div className="text mb-30">
                  {translationService.getTranslation(dataAbout.translations, 'about_title6',currentLanguage)}
                </div>
                <ul>
                  {
                    dataAbout.features.map((feature, index) => (<li key={index}> <i className="bi bi-star-fill me-3"></i> {feature.title && feature.title[currentLanguage]}</li>))
                  }
                </ul>
                <Link to={urlHelper.getLocalizedUrl('#')} className="btn rounded-pill border-blue2 hover-blue2 mt-60 sm-butn">
                  <span>{translationService.getTranslation(dataAbout.translations, 'about_title7',currentLanguage)}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="img img-right">
          <img src="/assets/img/about/style_3_2.png" alt="" />
        </div>
      </div>
    </section>
  )
}
export default About