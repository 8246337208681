import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { shortenString } from '../../common/shortenString';

const RssFeed = () => {
    const data = useStaticQuery(graphql`
      query {
        allFeedBbcNewsTech(limit: 3) {
          edges {
            node {
              title
              link
              content
              contentSnippet
              pubDate
              media {
                thumbnail {
                  attrs {
                    url
                  }
                }
              }
              internal {
                type
              }
            }
          }
        }
      }
    `);

 // Ordenar los elementos por fecha de publicación y seleccionar los 4 más recientes
 const rssItems = data.allFeedBbcNewsTech.edges
 .sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate));

  return (
    
rssItems.map(({ node }, index) => (
<div className={`card border-0 bg-transparent rounded-0 ${index !== rssItems.length - 1 ? 'border-bottom brd-gray':''} pb-4 mb-4`} key={index}>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <Link to={node.link} className="img img-cover">
                  <img src={node.media.thumbnail.attrs.url} alt={node.title} />

                </Link>
              </div>
              <div className="col-lg-8">
                <div className="card-body p-0">
                  <small className="d-block date text">
                            <a href={node.link} className="text-uppercase border-end brd-light pe-3 me-3 color-blue2 fw-bold">{node.title}</a>
                    <i className="bi bi-clock me-1"></i>
                            <a href={node.link} className="op-8">{new Date(node.pubDate).toLocaleDateString()}</a>
                  </small>
                          <h6 className="card-title"><Link to={node.link}>{shortenString(node.content, 40) }</Link></h6>
                  <div className="d-flex small mt-20 align-items-center justify-content-between op-9">
                    <div className="l_side d-flex align-items-center">
                    <a href={node.link}>
                      <span className="icon-11 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue2 p-2 me-2 text-white">
                      BBC News
                      </span>

                                <small className="text-muted"></small> Ver Más
                      </a>
                    </div>
                    <div className="r-side mt-1">
                      <i className="bi bi-chat-left-text me-1"></i>
                              <a href="#">{ node.comments }</a>
                      <i className="bi bi-eye ms-4 me-1"></i>
                              <a href="#">{ node.views }</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           ))
         );
        }

  


export default RssFeed;