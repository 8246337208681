import React, { useEffect, useRef,useContext, useState } from 'react';
import navbarScrollEffect from "common/navbarScrollEffect";
import scrollToSection from 'common/scrollToSection';
import LanguageSelector from '../LanguageSelector/LanguageSwitcher';
import api from '../../../common/api';
import { LanguageContext } from '../../../context/LanguageContext';

const OnePageNav = () => {
  const navbarRef = useRef(null);
  const { currentLanguage } = useContext(LanguageContext);
  const [navbarData, setNavBarData] = useState({navbar:[]});

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  useEffect(() => {
    const sections = document.querySelectorAll('[data-scroll-index]');

    window.addEventListener('scroll', () => {
      sections.forEach(section => {
        const index = section.getAttribute('data-scroll-index');
        const offset = section.offsetTop;
        const height = section.offsetHeight;
        const scroll = window.scrollY;

        if (scroll + 200 > offset && scroll + 200 < offset + height) {
          document.querySelector(`[data-scroll-nav="${index}"]`).classList.add('active');
        } else {
          document.querySelector(`[data-scroll-nav="${index}"]`).classList.remove('active');
        }
      });
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('navbardata', 'navbar.json');
        setNavBarData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  },[], [currentLanguage]);

  const handleMouseMove = (event) => {
    const dropDownToggler = event.target.classList.contains('dropdown-toggle') ? event.target : event.target.querySelector('.dropdown-toggle');
    const dropDownMenu = dropDownToggler?.nextElementSibling;

    dropDownToggler?.classList?.add('show');
    dropDownMenu?.classList?.add('show');
  }

  const handleMouseLeave = (event) => {
    const dropdown = event.target.classList.contains('dropdown') ? event.target : event.target.closest('.dropdown');
    const dropDownToggler = dropdown.querySelector('.dropdown-toggle');
    const dropDownMenu = dropdown.querySelector('.dropdown-menu');

    dropDownToggler?.classList?.remove('show');
    dropDownMenu?.classList?.remove('show');
  }

  
 
  return (
    <nav className="navbar navbar-expand-lg navbar-dark style-3 position-absolute w-100" ref={navbarRef}>
      <div className="container">
        <a className="navbar-brand" href="#0" data-scroll-nav="0" onClick={scrollToSection}>
          <img src="/assets/img/logo_ll.png" alt="" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-uppercase">
          {navbarData.navbar.map((item, index) => (
              <li className="nav-item" key={index}>
                <a className="nav-link" data-scroll-nav={item.dataindexnav} onClick={scrollToSection}>
                  {item.language[currentLanguage]}
                </a>
              </li>
          ))}
          </ul>
          <div className="nav-side">
            <div className="d-flex ps-4">
              <a href="#" className="search-icon me-5">
                <i className="bi bi-search"></i>
              </a>
              <div className="dropdown" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
               <LanguageSelector />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default OnePageNav