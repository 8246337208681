class Api {
  constructor() {
    if (!Api.instance) {
      Api.instance = this;
    }
    return Api.instance;
  }

  async get(endpoint, jsonFile) {
    const data = await this.readJsonFile(jsonFile);
    if (!data[endpoint]) {
      throw new Error(`Error fetching ${endpoint}: Endpoint not found`);
    }
    return data[endpoint];
  }

  async readJsonFile(jsonFile) {
    const filePath = `/data/${jsonFile}`;
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error(`Error fetching ${jsonFile}: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  }

  // Otros métodos HTTP (POST, PUT, DELETE, etc.) pueden ser añadidos aquí si es necesario
}

const instance = new Api();
Object.freeze(instance);

export default instance;