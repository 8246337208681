import React,{ useEffect, useState,useContext } from 'react';
import { Link } from 'gatsby';
import api from '../../common/api';
import { LanguageContext } from '../../context/LanguageContext';
import RssFeed from '../Utils/RssFeed';
const Blog = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [dataBlog, setBlogs] = useState({blogs: [], faq: [],clients: [],text_traductions:[]});
  const blog_titleResult =  dataBlog.text_traductions[currentLanguage] && dataBlog.text_traductions[currentLanguage].blog_title1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('blog','blog.json');
        setBlogs(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <section className="blog section-padding style-3" data-scroll-index="7">
      <div className="container">
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-5">
            <div className="blog-content">
              <div className="section-head style-3 d-flex align-items-center mb-50" >
                <div dangerouslySetInnerHTML={{ __html: blog_titleResult }}></div>
               <Link to="https://www.bbc.com/innovation/technology" className="text-muted ms-5 ps-5 mt-2">{dataBlog.text_traductions[currentLanguage] && dataBlog.text_traductions[currentLanguage].all_articles} <i className="bi bi-chevron-right ms-1"></i></Link>
              </div>
              <RssFeed/>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="faq style-3">
              <div className="section-head style-3 text-uppercase mb-50">
                <h3>FAQS</h3>
              </div>
              <div className="accordion" id="accordionExample">
                {
                  dataBlog.faq.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={`heading${index+1}`}>
                        <button className={`accordion-button ${index === 0 ? '':'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index+1}`} aria-expanded="true" aria-controls={`collapse${index+1}`}>
                          { item.question && item.question[currentLanguage] }
                        </button>
                      </h2>
                      <div id={`collapse${index+1}`} className={`accordion-collapse collapse ${index === 0 ? 'show':''}`} aria-labelledby={`heading${index+1}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          { item.answer && item.answer[currentLanguage] }
                        </div>
                      </div>
                    </div>
                  ))
                }
                <Link to="/page-contact-5" className="text-muted text-uppercase mt-50 small">
                  {dataBlog.text_traductions[currentLanguage] && dataBlog.text_traductions[currentLanguage].see_more} <i className="bi bi-chevron-right ms-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="client-logos mt-100">
          <div className="row align-items-center">
            {
              dataBlog.clients.map((client, index) => (
                <div className="col-6 col-lg-2" key={index}>
                  <a href="#" className="img d-block">
                    <img src={client} alt="" />
                  </a>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <img src="/assets/img/blog/v_lines.png" alt="" className="v_lines" />
    </section>
  )
}

export default Blog